@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .grid-fluid-72 {
        grid-template-columns: repeat(
            auto-fill,
            minmax(min(theme('spacing.72'), 100%), 1fr)
        );
    }
    .w-min-lg {
        width: min(36rem, 100%);
    }
}
